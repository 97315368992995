// Orange Boosted with Bootstrap
// Helvetica Neue LT W07 55 Roman
// <version>1.0</version>
// <vendor>Monotype Imaging Inc.</vendor>
// <credits>
// <name>Fonts.com WebFonts</name>
// <URL>http://webfonts.fonts.com</URL>
// </credits>
// <license>
// <URL>http://webfonts.fonts.com/Legal</URL>
// </license>
// <copyright>Copyright © 2014 Monotype Imaging Inc. All rights reserved.
// <trademark>Neue Helvetica is a trademark of Monotype Imaging Inc. registered in the U.S. Patent and Trademark Office and may be registered in certain other jurisdictions.
// Orange Company had buy the right for used Helvetica onto digital applications.
// Don't use and distribute Helvetica font family if you're not explicitly authorized by Monotype Imaging Inc

// 1. To use local() Helvetica Neue:
// @see https://www.broken-links.com/2009/06/30/checking-for-installed-fonts-with-font-face-and-local/
// @see https://www.adobe.com/content/dam/acom/en/devnet/font/pdfs/5090.FontNameList.pdf
// @note Desktop and Web font do not match (at least Orange's version on Windows)
// @note We'd have to use `font-size-adjust: .5` to ensure they do
// @note But this is not supported in IE11 nor Edge
// @see https://caniuse.com/#search=font-size-adjust
// @see https://developer.mozilla.org/en-US/docs/Web/CSS/font-size-adjust

// stylelint-disable value-list-comma-newline-after, value-list-comma-space-after

@import "functions";
@import "variables";

@font-face {
  font-family: HelvNeueOrange;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    // local("Helvetica 55 Roman"), local("HelveticaNeue"), // 1
    url("#{$icon-font-path}HelvNeue55_W1G.woff2") format("woff2"),
    url("#{$icon-font-path}HelvNeue55_W1G.woff") format("woff");
}

@font-face {
  font-family: HelvNeueOrange;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    // local("Helvetica 75 Bold"), local("HelveticaNeue-Bold"), // 1
    url("#{$icon-font-path}HelvNeue75_W1G.woff2") format("woff2"),
    url("#{$icon-font-path}HelvNeue75_W1G.woff") format("woff");
}
